import logo from './logo.svg';
import { Profile } from './pages/Profile';
import './App.css'

function App() {
  return (
    <div className="App">
      <Profile />
    </div>
  );
}

export default App;
