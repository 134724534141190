import React from 'react';
import { FaEnvelope, FaLinkedin, FaTwitter } from 'react-icons/fa';
import './Profile.css';

export const Profile = () => {
    return (
        <div className="container">
            <div className="sidebar">
                <img src="/darthjaja.png" alt="Profile" className="profile-pic" />
                <div className='name'>Wei Duan</div>
                <div className='headline'>May the PMF be with you</div>
                <div className="contact-links">
                    <a href="https://twitter.com/darthjaja198468" target="_blank" className="contact-link">
                        <FaTwitter />
                    </a>
                    <a href="mailto:weiduan1025@gmail.com" target="_blank" className="contact-link">
                        <FaEnvelope />
                    </a>
                    <a href="https://www.linkedin.com/in/wei-duan-a2a696125/" target="_blank" className="contact-link">
                        <FaLinkedin />
                    </a>
                </div>
                <p>Hi, I'm Wei. I've been working in the intersection between ML research, engineering and product for years. 👉 are the AI products I've built</p>
                {/* <p>Hi, I'm Wei, an engineer passionate about building products. My goal is to build great products(Plz see some of my efforts👉). My mindset of building product(and doing everything else) is pretty much a physicist way(Yes I love Physics!): Observing, making hypothesis, doing experiment that falsifies the theory and go back to the start, until there's a good theory. In and out of my work, I always enjoy talking to people, learn about their life and hack MVPs to solve their headaches, and iterate. Aside from work, I read a lot, here are the works I have recommended to friends</p>
                <ul>
                    <li>The Minimalist Entrepreneur:How Great Founders Do More With Less</li>
                    <li>Radical Markets</li>
                    <li>Sovereign Individual</li>
                    <li>微信背后的产品观(The Philosophy of Product Design behind Wechat)</li>
                    <li>The Three-Body Problem</li>
                </ul>
                <p>Besides, I like doing investment. I believe investment style reveals personality well. Investing is my way to reflect on my own personality and watch how it grows overtime.</p>
                <p>Things I do daily: sleeping, eating, reading ML paper, exploring new products and swimming. I enjoy keeping doing these simple things well for years.</p> */}
            </div>
            <div className="work-container">
                <div className="work-item">
                    <a href="https://www.evawork.com/" target="_blank">evawork</a>
                    <p>AI intern who helps ship operators make paperwork 10x faster</p>
                    <p>Shipping is heavy, so is the paperwork for shipping! Ship operators spend 1hr+ daily on making LOI files for a voyage</p>
                    <p>This is an MVP that was loved by ship operators (still trying to figure out how to take money from their boss's pockets)</p>
                </div>
                <div className="work-item">
                    <a href="https://supafocus.vercel.app/" target="_blank">supafocus</a>
                    <p>Quiz generator that helps users focus on watching Youtube courses</p>
                    <p>Despite all the AI summarizers around here, sometimes there's no short path to knowledge - to actually learn something we still have to spend time watching or reading every single word</p>
                    <p>But sometimes they are too long...</p>
                    <p>Supafocus generates quizzes of youtube videos. Each quiz serves a short term goal to help you stay focused on a long video. Upload your own video or jump in to learn <a href="https://supafocus.vercel.app/q/IuQVxGjSu3SyTwuueQwWK" target="_blank">What is a quantum well</a></p>
                    <p>Loved by friends in school, who apparently need some focus before finals</p>
                </div>
                <div className="work-item">
                    <a href="https://github.com/agentware/agentware" target="_blank">agentware</a>
                    <p>A framework that helps build LLM agents that can learn new stuff and correct obsolete knowledge from conversation</p>
                    <p>This is a technical exploration of how far we can go in building and updating knowledge base without finetuning LLMs</p>
                </div>
                <div className="work-item">
                    <a href="https://imagineapp.co/" target="_blank">imagineapp</a>
                    <p>A tool that helps musicians make MVs</p>
                </div>
                <div className="work-item">
                    <a href="https://askweiduan.com/" target="_blank">sparknote</a>
                    <p>Simplest note taking app like a private twitter</p>
                    <p>It has been for my personal use for several years. I'm recently doing some experiments on making an AI friend from it</p>
                </div>
            </div>
        </div>
    );
}
